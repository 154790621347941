<template>
  <div class="p-grid all-matches">
    <div class="p-col-12 p-p-0">
      <div>
        <view-switcher
          :layout="layout"
          :loading="loading"
          :length="profiles.length"
          @toggleView="toggleView"
        />
      </div>
      <div v-if="profiles.length !== 0 || loading">
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :load-profiles="loadProfiles"
          :profile-number="profileNumber"
        />
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :load-profiles="loadProfiles"
          :profile-number="profileNumber"
        />
      </div>
      <div v-else>
        <empty-mails
          :empty-text="'No profiles found'"
          :img="$route.name === 'Nowraans' ? '/groom.png' : '/bride.png'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import EmptyMails from '@/components/EmptyMails.vue';
import { profileInterestMixin } from '@/mixins/profileInterestMixin.js';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [profileInterestMixin, mailboxMixin],
  props: { reqUrl: { type: String, required: true } },
};
</script>
