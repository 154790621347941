export const profileInterestMixin = {
  methods: {
    shortlistProfile(id, shortlisted) {
      let that = this;
      this.$http.patch(`/users/${that.authUser.slug}/add_or_remove_shortlist`, {
        shortlisted: shortlisted,
        user_id: id,
      });
    },
    sendinterest(to_user_id) {
      let that = this;
      this.$http.post('/interests', {
        interest: { from_user_id: that.authUser.id, to_user_id: to_user_id },
      });
    },
  },
};
